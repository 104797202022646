.hide-scroll-bar {
  scrollbar-width: none !important;
}

.divideAmount {
  width: 472px;
  max-height: auto;
  margin: 0 20px 20px 20px;
  overflow-y: auto;
}
.input_Box_url::placeholder {
  color: #dadada !important;
  font-size: 16px !important;
}
.input_Box_url {
  height: 42px !important;
  background-color: transparent;
  padding: 10px;
  font-size: 16px;
  line-height: 22px;
}
.upload_img_summary_p1 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  margin-bottom: 10px;
}

.upload_img_summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.upload_img_summary_p2 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}

.top_div_upload {
  display: flex;
}

.upload_cancel {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40%;
  cursor: pointer;
  right: 20px;
}

.beneContact_Main .MuiFormControl-root .MuiInputLabel-root {
  height: 20px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .divideAmount {
    padding: 0 20px;
    margin: 0 !important;
  }
}
.beneAcc_date_heading_leftm_txn {
  display: flex;
  text-align: left;
  font: normal normal normal 16px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 10px;
}
.beneAcc_date_heading_rightm_txn {
  display: flex;
  font: normal normal normal 16px Open Sans;
  letter-spacing: 0px;
  justify-content: end;
  opacity: 1;
  margin-left: 47px;
  margin-right: 10px;
}

@media screen and (max-width: 400px) {
  .upload_img_summary {
    width: 130px;
  }

  .upload_img_summary_p1 {
    width: inherit;
  }

  .upload_cancel {
    right: 10px;
  }
}
