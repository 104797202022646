.bene_Stepper_Main {
  width: 1024px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom_create {
  color: #ffffff !important;
  background-color: var(--Blue_7) !important;
  width: 126px !important;
  height: 60px !important;
  text-transform: none !important;
  text-align: left !important;
  font: normal normal 16px/22px Open Sans !important;
  letter-spacing: 0px !important;
  border-radius: 0px !important;
}

.custom_create_next {
  width: 126px !important;
  height: 60px !important;
  text-transform: none !important;
  text-align: left !important;
  font: normal normal 16px/22px Open Sans !important;
  letter-spacing: 0px !important;
  border-radius: 0px !important;
}

.stepperline_Div_Create {
  width: 772px;
  height: 59.85px;
  padding: 0px 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step_Icon {
  height: 18px;
  width: 18px;
}

.stepperline_Div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.stepinnerline {
  height: fit-content;
  width: fit-content;
}

.stepDetails {
  font-size: 12px;
  color: var(--Primary_white);
  width: fit-content;
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;
}

.create_Button {
  color: #ffffff !important;
  height: 59.85px;
  background-color: var(--Button_green) !important;
  width: 126px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.main_Line {
  height: 7px;
  border-radius: 4px;
  background-color: #ffffff;
}

@keyframes growHeight {
  from {
    width: 100px;
  }
  to {
    width: 220px;
  }
}

.growing-element {
  animation: growHeight 0.7s backwards;
}

@media screen and (max-width: 800px) {
  .stepperline_Div_Create {
    width: 100% !important;
    padding: 0px 15px !important;
    gap: 15px;
  }

  /* .stepinnerline {
    width: 100% !important;
    display: flex;
    justify-content: center;
  } */

  .growing-element {
    animation: growMobileWidth 0.7s backwards !important;
  }

  .bene_Stepper_Main {
    height: 80px !important;
    width: 100% !important;
    padding: 0 5px !important;
  }

  .stepDetails {
    display: none !important;
  }

  .custom_create_next {
    height: 45px !important;
    border-radius: 25px !important;
    width: 150px!important;
  }

  .custom_create {
    height: 45px !important;
    border-radius: 25px !important;
    width: 150px!important;
  }

  .create_Button {
    border-radius: 25px !important;
    height: 45px !important;
  }
}
