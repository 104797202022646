.disabled_pop_totp {
  width: 100vw;
  height: 100vh;
  background-color: #000000cc;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled_pop_totp_box {
  width: 560px;
  height: 223px;
  border-radius: 20px;
  background-color: #1d3a6d;
  padding: 20px;
}

.disabled_pop_totp_box_1 {
  width: 128px;
  height: 27px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ff7f33;
  margin-bottom: 10px;
}

.disabled_pop_totp_box_2 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  margin-bottom: 20px;
}

.disabled_pop_totp_box_3 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dadada;
  margin-bottom: 50px;
}

.disabled_pop_totp_box_butt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.disabled_pop_totp_box_butt_can {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  text-decoration: underline;
}

.disabled_pop_totp_box_butt_con {
  width: 79px;
  height: 32px;
  padding: 8px 16px 8px 16px;
  border-radius: 75px;
  background-color: #69b76f;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  margin-left: 20px;
}

.disabled_pop_totp_box_butt > img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
