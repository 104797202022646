.totp_Top_Main {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.divide_totp {
  width: 472px;
  max-height: auto;
  margin: 0 20px 20px 20px;
  overflow-y: auto;
  overflow: hidden;
}

.totp_download_left {
  width: 472px;
  height: 348px;
  border-radius: 20px;
  padding: 20px;
  background-color: #314d7e;
}

.totp_download_right {
  width: 472px;
  height: 359px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #314d7e;
}

.totp_download_left_1 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dadada;
  margin-bottom: 20px;
}

.totp_download_left_2 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dadada;
  margin-bottom: 20px;
}

.totp_download_left_3 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dadada;
  margin-bottom: 5px;
}

.totp_auth_app_box {
  display: flex;
}

.totp_auth_box {
  width: 146.75px;
  height: 28px;
  background-color: #506994;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.totp_auth_box_play {
  width: 146.75px;
  height: 28px;
  background-color: #506994;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.totp_auth_app {
  display: flex;
  margin-bottom: 10px;
}

.totp_auth {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0px;
  flex-direction: column;
}

.totp_auth_google {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0px;
  border-bottom: 1px solid #102c5d;
  flex-direction: column;
}

.totp_auth_app > img {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
}

.totp_auth_app > h4 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}

.totp_auth_box_playstore {
  width: 60.75px;
  height: 18px;
}

.totp_auth_box_qrcode {
  width: 16.56px;
  height: 16.56px;
  cursor: pointer;
}

.totp_download_right > img {
  width: 287.8px;
  height: 250px;
  margin-top: 20px;
}

.totp_download_right_button {
  width: 432px;
  height: 59px;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  background-color: #3a5686;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.totp_download_right_button > img {
  width: 19.22px;
  height: 19.22px;
  margin-right: 10px;
}

.totp_download_right_button_info > h4 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dadada;
}

.totp_auth_box_play::after {
  content: "";
  position: absolute;
  background-color: #000000;
  width: 0.5px;
  height: 16px;
  top: 21.5%;
  right: 26%;
}

.totp_download_right_button_info > p {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  margin-top: 5px;
}

.redirect_totp {
  width: 16.56px;
  height: 18px;
  margin-left: 10px;
}

.totp_auth_box_play_split {
  display: flex;
}
