.Owner_tag-box-item {
  display: flex;
  flex-direction: row;
  border-radius: 20px !important;
  background: #3a5686;
  box-sizing: border-box;
  grid-column: span 1;

  margin: 10px 20px 10px 0;
  padding: 10px !important;
  width: fit-content;
  cursor: pointer;
}

/* @media (max-width: 1024px) {
  .OwnerTagArrow-responsive {
    padding: 10px;
  }
}

@media (max-width: 370px) {
  .OwnerTagArrow-responsive {
    margin-left: 10px;
    margin-right: 10px;
  }
} */

.tag-box-item {
  display: flex;
  flex-direction: row;
  border-radius: 20px !important;
  background: #3a5686;
  box-sizing: border-box;
  grid-column: span 1;

  margin: 10px 20px 10px 0 !important;
  padding: 10px !important;
  width: fit-content;
  cursor: pointer;
}

.Owner_tag-box-item > div {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
  font: normal normal normal 400 12px/17px Open Sans;
  color: #f9f9f9;
}

.dropdown-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
}

.dropdown-div {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-content {
  background-color: #667ca3;
  max-height: 135px;
  overflow-y: auto;
  border-radius: 10px;
  z-index: 9;
  height: 90px;
  left: -15.5px;
  top: 25px;
  margin-top: 6px;
  border-top: none;
}

.dropdown-content::before {
  content: "";
  position: absolute;
  top: 27px;
  left: 50%; /* Center the border */
  width: 90%; /* Adjust the length of the border */
  height: 1px; /* Border thickness */
  background-color: #244277; /* Border color */
  transform: translateX(-50%); /* Center the border */
}

.dropdown-item1 {
  padding: 5px;
  margin: 0px 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-item {
  padding: 8px 8px;
  cursor: pointer;
  color: #f9f9f9;
}

.dropdown-item {
  /* margin-bottom: 20px; */
  padding: 5px;
  margin: 0px 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-img-div {
  display: row !important;
  align-items: center;
}

.dropdown-item:hover {
  /* background-color: #8596B5; */
  padding: 5px;
  border-radius: 5px;
}

.dropdown-item img,
.dropdown-item1 img {
  width: 16px;
  height: 16px;
}

.dropdown-item p, .dropdown-item1 p {
  /* Option 1 */

  width: 170px;
  height: 16px;

  /* Web Font/12 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #f9f9f9;
}

.dropdown-div-responsive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 10px;
}

.dropdown-content-responsive {
  position: absolute;
  background-color: #667ca3;
  width: 100%;
  max-height: 155px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 0px 0px 10px 10px;
  z-index: 9;

  top: 25px;
  border: 1px solid #ff5860;
  padding-top: 5px;
  border-top: none;
}