.scanner_main {
  width: 100vw;
  height: 100vh;
  background-color: #000000cc;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanner_div {
  width: 472px;
  height: 366px;

  padding: 20px;
  border-radius: 20px;
  background-color: #314d7e;
}

.scanner_div_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scanner_div_top > h4 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}

.scanner_div_top > img {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.scanner_div_bottom {
  width: 260px;
  height: 260px;
  border-radius: 10px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanner_div_bottom_top {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
