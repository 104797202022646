.device-content-page {
  width: 100%;
  background-color: #3a5686;
  height: 480px;
  margin-top: 72px;
  box-shadow: 5px 5px 11px #00000026;
}

.browserLogo-div,
.deviceLogo-div,
.token-div,
.deleteDevice-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
  text-align: center;
  gap: 0.25rem; 
  padding: 0.5rem;
  width: 100px;
}

.browserLogo-div {
 box-shadow: inset 0 10px 15px -5px rgba(0, 0, 0, 0.3);
 cursor: default;
}



.token-div,
.deleteDevice-div {
  background-color: #506994;
  width: 100px;
}

.token-div button,
.browserLogo-div button, 
.deviceLogo-div button,
.deleteDevice-div button {
 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}


.token-div .token-img,
.browserLogo-div .browser-logo, 
.deviceLogo-div .device-logo,
.deleteDevice-div .deleteDevice-img {
  height: 25px;
  margin-bottom: 5px;
}


.device-container {
  width: 100%;
  background-color: #3a5686;
  height: 71vh;
  height: 30%;
  /* min-height: 15%; */
  margin: auto;
}

.device-section {
  height: 30%;
  background-color: #3a5686;
  max-width: 1024px;
  margin: auto;
  display: flex;
}

.device-major-div {
  width: 900px;
  margin-top: 29px;
}

.individual-device-detail-first,
.individual-device-detail-second,
.individual-device-detail-third {
  height: 90px;
  display: flex;
  border-bottom: 2px solid #1d3a6d;
}

.createdBy-div,
.lastUsed-div {
  padding: 20px;
  padding-top: 25px;
  width: 50%;
}

.device-number-balance {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;
  margin-left: 22px;
  margin-top: -22px;
}

.device-refresh-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15px;
  margin-left: 6px;
  margin-left: 6px;
  margin-top: 2px;

  border-left: 2px solid #f9f9f9;
}

.model-div,
.platform-div,
.loggedIn-div {
  padding-top: 29px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;
}

.device-text-label {
  color: #C9C9C9;
  opacity: 1;
  font-size: 0.75rem;
  font: normal normal normal 18px/25px Open Sans;
letter-spacing: 0px;
color: #C9C9C9;
opacity: 1;
}

.device-entity {
  font: normal normal normal 17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.details-heading {
 width: 80px;
height: 28px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 18px;
opacity: 1;
color: #3A3649;
font-size: 12px;
margin-top: 20px;
}

.device-content {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  border-left: 2px solid #1d3a6d;
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.token-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo-bank-container {
  background-color: "" !important;
  height: 88.5px;
  width: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailed-bank-logo {
  width: 80px;
}


.device-minor-div {
  margin-top: 29px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 270px;
}

.device-content-detail {
  justify-content: center;
  height: 48px;
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  border-left: 2px solid #1d3a6d;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.flex-device-div {
  display: flex;
  justify-content: flex-end;
  height: 90px;
  width: 100px;
  border-bottom: 2px solid #1d3a6d;
}


.os-div {
  padding: 20px;
  width: 50%;
}

.acc-status-div {
  padding-top: 26px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;
}

.pending-status {
  color: #30e5e4;
}

.approved-status {
  color: #4ddd37;
}

.denied-status,
.blocked-status {
  color: #ff5860;
}

.archived-status {
  color: #a3a3a3;
}

.active-status {
  color: #4ddd37;
}

.not-active-status {
  color: #ff5860;
}

.recipient {
  height: 90px;
  border-bottom: 2px solid #1d3a6d;
  display: flex;
}

.recipient-box2 {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 33.3%;
  border-right: 2px solid #1d3a6d;
}

.recipient-box3 {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 34%;
}

.recipient-box4 {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 34%;
}

.recipient-img {
  width: 35px;
  height: 35px;
}

.device-image-label {
 font: normal normal normal 10px/14px Open Sans;
letter-spacing: 0px;
color: #1D3A6D;
opacity: 1;
}

.image-label {
  font-size: 10px;
  color: #ffffff !important;
}



.statement-img {
  background: #506994;
  opacity: 1;
}


.device-details-div-section {
  width: 100%;
  background-color: #1d3a6d;
  margin-top: 255px !important;
  margin-bottom: 20px;
}

.device-details-section {
  height: auto;
  background-color: #1d3a6d;
  max-width: 1024px;
  margin: auto;
  padding-top: 10px;
}

.details-btn {
  width: 80px;
  height: 30px;
  background-color: #f9f9f9;
  border-radius: 18px;
  margin-right: 20px;
  color: #3a3649;
  font-size: 0.75rem;
  text-align: center;
  font-style: Open, Sans;
  font-weight: 600;
  text-align: center;
}

.device-detail-card {
  width: 328px;
  height: 250px;
  margin-top: 20px;
  background-color: #314d7e;
  border-radius: 20px;
  box-shadow: -5px -5px 11px #71717133;
  padding: 20px;
}

.device-detail-label {
  letter-spacing: 0px;
color: #C9C9C9;
opacity: 1
}



.title1 {
  font-size: 0.8rem;
  font-weight: 600;
  color: #dadada;
  margin-bottom: 10px;
}

.data-div {
  font-size: 0.9rem;
  color: #ffffff;

  padding-bottom: 4px;
}

.basic-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: #c9c9c9;
  padding-bottom: 10px;
  border-bottom: 2px solid #1d3a6d;
  width: 100%;
}

.acc-type-section {
  margin-top: 10px;
}

.ifsc-code-div {
  font-size: 0.8rem;
  color: #c9c9c9;
  padding-bottom: 10px;
  width: 100%;
}

.device-status-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: #c9c9c9;
  width: 100%;
}

.owner-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: #c9c9c9;

  width: 100%;
}

.statementNotFound {
  width: 224px;
  height: 90px;
  background: #bb5353 0% 0% no-repeat padding-box;
  opacity: 1;
}

.support-detail-card {
  width: 328px;
  height: 180px;
  background-color: #314d7e;
  border-radius: 20px;
  box-shadow: -5px -5px 11px #71717133;
  padding: 20px;
}

.addDetails-div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
