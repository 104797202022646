.dashboard-card {
  width: 315px;
  position: relative;
}

.maindashboard-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #506994;
  /* height: 67px; */
  border-radius: 10px;
  opacity: 1;
  /* padding: 5px 0px 5px 10px; */
  padding-left: 10px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}

.maindashboard-card .maindashboard-card-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
}

.maindashboard-card .maindashboard-card-left p {
  text-align: right;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #F9F9F9;
  opacity: 1;
}

.maindashboard-card .maindashboard-card-left p:nth-child(2) {
  text-align: left;
  font: normal normal normal 10px/11px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.maindashboard-card .maindashboard-card-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.maindashboard-card .maindashboard-card-right .right-first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
}

.maindashboard-card .maindashboard-card-right .right-first-child p {
  text-align: right;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #4DDD37;
  opacity: 1;
}

.maindashboard-card .maindashboard-card-right .right-first-child p:nth-child(2) {
  text-align: left;
  font: normal normal normal 10px/11px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.maindashboard-card .maindashboard-card-right .right-second-child {
  width: 29px;
  height: 50px;
  border-radius: 0px 10px 10px 0px;
  background: #667ca3 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.maindashboard-card .maindashboard-card-right .right-second-child img {
  height: 16px;
  width: 30px;
}

.maindashboard-card .maindashboard-card-right .right-second-child:hover {
  background-color: #667ca3;
}

.arrowUpDown-div {
  position: absolute;
  height: 10px;
  background-color: green;
}

.arrowUpDown-div-up {
  position: absolute;
  top: 26px;
  right: 45px;
}

.arrowUpDown-div-down {
  position: absolute;
  top: 9px;
  right: 48px;
}

.bank-logom {
  width: 54px;
  float: left;
}

.bank-account-no {
  float: left;
  width: 65%;
  font-size: 12px;
  padding: 5px 10px;
}

.maindashboard-card-leftg {
  width: 100%;
  height: 35px;
}

.maindashboard-card-leftgm {
  width: 100%;
}

.bank-account-name {
  width: 100%;
  padding: 3px 15px 10px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}

.bank-logo .card-logo {
  border-radius: 0 0 20px 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
}

.balance-amt {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #dedede;
  opacity: 1;
}

.balance-text {
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-top: 5px;
}
