.success-detail-conatiner {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  padding-top: 3%;
  width: 468px;
  height: 340px;
  padding: 3px;
  overflow: scroll;
  padding-left: 30px;
  padding-right: 40px;
  padding-bottom: 16px;
}

.pan-section-container {
  max-width: 800px;
  max-height: 355px;
}

.footer-div-navigate {
  position: relative;
  bottom: 28px;
}

.success-detail-conatiner::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

.success-detail-conatiner::-webkit-scrollbar-thumb {
  background-color: #dadada;
  outline: 1px solid #dadada;
  border-radius: 25px;
  height: 20px;
}

.toggle-btn-container {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #4d8352;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  cursor: pointer;
  height: 70px;
}

.toggle-btn-label {
  height: 60px;
  color: #f9f9f9;
  letter-spacing: 0px;
  font-size: 14px;
  padding: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.make-text-lower {
  text-transform: none;
}

.vertical-line-seperater {
  background-color: #dadada;
  max-width: 1px;
  height: 37px;
  position: relative;
  z-index: 5;
}

.success-button-conatiner {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  bottom: -30px;
}

.pay-bene-box2-decline {
  height: 120px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ea545b;
  padding-bottom: 30px;
  cursor: pointer;
}

.pay-bene-box2-Approve {
  height: 120px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #69b76f;
  padding-bottom: 30px;
  cursor: pointer;
}

.pay-bene-box2-decline-disabled {
  height: 120px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ea545c8d;
  padding-bottom: 30px;
  cursor: default;
}

.pay-bene-box2-Approve-disabled {
  height: 120px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #69b77090;
  padding-bottom: 30px;
  cursor: default;
}

.pay-bene-box2-decline:hover {
  background-color: #ea545cb0;
}

.pay-bene-box2-Approve:hover {
  background-color: #69b77090;
}

.approve-reject-rights {
  position: relative;
  z-index: 999;
  top: 15px;
}

.approve-reject-rights-block {
  height: 360px;
}

@media screen and (max-width: 800px) {
  .bene_CreateSuccessMainContainer {
    height: 100vh;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .toggle-btn-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    justify-content: space-evenly;
  }

  .statusBoth {
    width: 33.33% !important;
  }

  .statusOne {
    width: 50% !important;
  }

  .statusDefault {
    width: 100% !important;
  }
  .bene_createAcc_succ_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .dashedLine {
    padding-left: 15px;
    padding-right: 15px;
  }
  .bene_createAcc_succImg_container {
    padding-top: 0rem;
    background-color: rgba(69, 117, 73, var(--tw-bg-opacity));
  }

  .bene_createAccsuccesImgDiv {
    padding: 20px;
  }

  .bene_createAccsuccessImg {
    width: 251px;
  }

  .bene_createAccsuccessText {
    padding-top: 15px;
  }
  .bene_createAccsuccessText p {
    font-size: 14px;
    line-height: 19.07px;
    text-align: center;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
  }

  .bene_accountCreationDetails {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
  }
  .bene_accDetailsCard {
    height: auto !important;
    margin: 0;
    border-radius: 0;
  }

  .toggle-btn-label {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    text-align: center;
  }

  .footer-div-navigate {
    position: fixed;
    bottom: -1px !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .approve-reject-rights-block {
    /* overflow-x: hidden;
    scrollbar-width: none; */
    height: 100%;
    overflow: hidden;
    margin-bottom: 38px;
    justify-content: normal;
    padding-bottom: 100px;
  }

  .bene_createAccount_name {
    padding: 0;
  }

  .bene_createAccount_nametext {
    padding-top: 15px !important;
    padding-bottom: 10px !important;
    font-size: 16px;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    line-height: 19px;
  }

  .content-center {
    /* margin-left: 3px;
    margin-right: 15px; */
  }

  .beneDetailsText {
    width: 100%;
  }

  .bene_createAcc_assignedCategories {
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
    overflow-wrap: break-word;
    width: 22rem;
  }

  .simpleLine {
    margin: 15px 0;
  }

  .pan-section-container {
    overflow-y: hidden;
    max-height: none;
    width: 100% !important;
    padding-bottom: 130px;
  }

  .pan-section-container p {
    width: 100%;
  }

  .accountsData {
    padding-left: 15px;
    padding-right: 15px;
  }

  .accountType {
    padding-left: 20px;
  }

  .serviceOptions {
    width: 35px;
    height: 35px;
  }

  .serviceOptionName {
    font-size: 12px;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    text-align: center;
    line-height: 16px;
  }

  .approve-reject-rights {
    z-index: 0;
    padding-bottom: 192px;
  }

  .success-button-conatiner {
    position: fixed;
    bottom: 80px;
  }
}
