.internalTxnDetailsPage {
  display: block;
  width: 100%;
  background-color: #3a5686;
  height: auto;
}

.internaltxn-account-primary-details {
  height: 30%;
  background-color: #3a5686;
  width: 100%;
  display: flex;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
}

.url-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.progress-require-approval::-webkit-progress-bar {
  background-color: #102c5d;
  border-radius: 15px;
}

.progress-require-approval::-webkit-progress-value {
  background-color: #69b76f;
  border-radius: 15px;
}

.progress-rejected-approval::-webkit-progress-bar {
  background-color: #102c5d;
  border-radius: 15px;
}

.progress-rejected-approval::-webkit-progress-value {
  background-color: #ff5860;
  border-radius: 15px;
}

.progress-abandoned-transaction::-webkit-progress-bar {
  background-color: #102c5d;
  border-radius: 15px;
}

.progress-abandoned-transaction::-webkit-progress-value {
  background-color: #b4b4b4;
  border-radius: 15px;
}

.internalTxnDetailsPageMob {
  display: none;
}

.infoBox {
  width: 20rem;
}

@media screen and (max-width: 1024px) {
  .internalTxnDetailsPage {
    display: none;
  }

  .internalTxnDetailsPageMob {
    display: block;
    height: 100vh;
    overflow: hidden scroll;
    scrollbar-width: none;
  }
}
.approval_timeline_line {
  width: 4%;
}
@media screen and (max-width: 340px) {
  .approval_timeline_line {
    width: 7% !important;
  }
}

@media screen and (max-width: 360px) {
  .infoBox {
    width: 16rem;
  }
}

@media screen and (max-width: 380px) and (min-width: 361px) {
  .infoBox {
    width: 17rem;
  }
}

@media screen and (max-width: 400px) and (min-width: 381px) {
  .infoBox {
    width: 18rem;
  }
}

@media screen and (max-width: 420px) and (min-width: 401px) {
  .infoBox {
    width: 19rem;
  }
}
@media screen and (max-width: 1024px) {
  .approval_timeline_line {
    width: 20px !important;
  }
}
.Distribution-box {
  border-top: 1px solid #031f4f !important;
}
@media screen and (min-width: 1024px) {
  .Distribution-box {
    border-top: none !important;
  }
}
