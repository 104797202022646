.user-card-main-div {
  width: 100%;
  height: auto;
}

.user-first-div {
  height: 200px;
  background: #314c7f 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  opacity: 1;
  display: flex;
}

.user-count-div {
  max-width: 1253px !important;
  margin: 0 auto;
}

.user-total-board {
  background: #314c7f 0% 0% no-repeat padding-box;
}

.part-one {
  flex: 28vw;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.part-two {
  flex: 72vw;
  box-shadow: 0px 3px 6px #00000029;
}

.view-all-user {
  font-family: Open Sans, Semibold;
  font-size: 20px;
  height: 28px;
  text-align: right;
  color: #fff;
}

.No-of-User {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 100px;
  margin-top: 110px;
}

.user-total-count-symbol {
  transform: matrix(0, -1, 1, 0, 0, 0);
  font-style: italic;
  color: #ffffff;
  margin-right: 4px;
  font-size: 1.125rem;
}

.user-count {
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0px;
  color: #dadada;
  font-style: Open, Sans;
  margin-right: 80px;
}

.snak-bar {
  display: flex !important;
  justify-content: space-between;
  width: 90% !important;
  margin: 0 auto;
  padding: 209px 26px 10px 8px;
}

.create-user {
  width: 136px;
  height: 36px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 28px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.crt-icon {
  font-size: 30px;
  color: #1d3a6d;
  opacity: 1;
  margin-right: 8px;
}

.crt-label {
  width: auto;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #1d3a6d;
  opacity: 1;
}

.user-second-div {
  max-width: 1253px;
  margin: 0 auto;
  background: #1d3a6d 0% 0% no-repeat padding-box;
}

.infinityScrollContainerUserSettings {
  display: flex !important;
  flex-wrap: wrap;
  height: auto;
  overflow-y: auto;
  margin: 0 auto;
  background: #1d3a6d 0% 0% no-repeat padding-box;
}

.card-viewer {
  max-height: 464px;
}

.card-viewer-data {
  display: flex !important;
  flex-wrap: wrap;
  margin: 0 auto;
}

.user-details-card {
  width: 238px;
  height: 140px !important;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  padding: 10px !important;
  margin: 5px;
  cursor: pointer;
}

.user-name {
  width: 190px;
  text-align: left;
  font: normal normal 600 16px/21px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.userViewallContainer {
  height: 240px;
}

.multiselectanimation {
  position: absolute;
  transition: all 0.2s ease-in-out;
  animation: goDowns 0.2s ease-in forwards;
  transform-origin: top center;
}

@keyframes goDowns {
  0% {
    top: -20px;
  }

  100% {
    top: 5px;
  }
}

.user-id {
  width: 220px;
  max-width: 220px;
  text-overflow: ellipsis;
  text-align: left;
  overflow-x: hidden;
  font: normal normal normal 12px/26px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  text-wrap: nowrap;
  opacity: 1;
  text-transform: capitalize;
}

.user-label {
  width: 217px;
  text-align: left;
  font: normal normal 400 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  display: flex;
  column-gap: 5px;
}

.user-label > img {
  height: 12px;
  width: 12px;
}

.userborderdiv {
  border-top: 1px solid #1d3a6d;
  margin: 10px -10px;
  width: 238px;
  /* height: 1px; */
}

.loader-user-viewall {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-user-viewall img {
  max-width: 100%;
  height: auto;
}

.user-not-found {
  color: #ffffff;
  opacity: 1;
  font-size: 1rem;
  text-align: center;
}

#infinityScrollContainerUserView {
  display: flex !important;
  flex-direction: column !important;
  overflow: auto;
  position: relative;
  height: 100%;
}

.user-details-card:hover {
  background-color: #506994;
}

.assigntagdiv {
  width: 173px;
  height: 30px;
  border-radius: 5px;
  padding: 6px 10px;
  background-color: #667ca3;
  column-gap: 5px;
  color: #f9f9f9;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  cursor: default;
}

.assigntagdiv > img {
  height: 18px;
  width: 18px;
}
.user-id > span {
  text-transform: none;
}

.UserCard {
  display: flex;
  flex-wrap: wrap;
  width: 81% !important;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 15px;
}

.user-div-container {
  background-color: #314c7f;
  background-color: #314c7f;
  margin-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.user-gradient {
  height: 125px;
  position: absolute;
  background-color: #506994;
  top: 15px;
}

.user-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  height: 140px;
  position: relative;
  align-items: center;
}

.user-acc-viewall {
  font-family: Open Sans, Semibold;
  font-size: 20px;
  height: 28px;
  text-align: right;
  color: #fff;
}

.No-of-user-accounts {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 12px;
  margin-top: 21px;
}

.user-acc-total-count-symbol {
  transform: matrix(0, -1, 1, 0, 0, 0);
  font-style: italic;
  color: #ffffff;
  margin-right: 4px;
  font-size: 1.125rem;
}

.view-count-user {
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0px;
  color: #dadada;
  font-style: Open, Sans;
}

.search-bar-hover {
  z-index: 999;
}
