.bene_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  align-items: center;
  height: 59px;
  max-width: 1024px;
}
.beneacc_filter_divm.filtered {
  background-color: #fff;
  width: 30px;
  height: 28px;
  border-radius: 5px;
  padding: 6px 11px 0px 0px;
}
.infinite-scroll-component__outerdiv {
  width: 100%;
  margin-bottom: 100px;
}

.bene_txn_viewall_toggle_containers_acc {
  margin-left: 10px;
  height: 30px !important;
  width: 68px !important;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background: #244277;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 5px;
  opacity: 1;
}

.bene-txn-viewall-pagination-containers_acc {
  height: 34px !important;
  width: 132px !important;
  display: flex;
  background: #244277;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  margin-left: 10px;
}

.bene-txn-viewall-page-select-div_acc {
  height: 34px !important;
  width: 130px !important;
  cursor: pointer;
  display: flex;
  background: #244277;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  margin-left: 10px;
}

.beneTxn_viewAll_search_div_acc {
  width: 55px;
  background-color: #244277;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* gap: 6px; */
  height: 30px;
  margin-right: 10px;
  position: relative;
}

.beneTxn_viewAll_search_div_acc.searched {
  background-color: #ffffff;
}

.acc_right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.acc_black_div {
  position: absolute;
  opacity: 0.8;
  background-color: #000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

.acc_black_divm {
  position: absolute;
  opacity: 0.8;
  background-color: #000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
}

.bene_div_container {
  background-color: #3a5686;
  position: fixed;
  padding-top: 105px;
  z-index: 999;
  height: 177px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.top_line_of_div {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1d3a6d;
  width: 100%;
  height: 105px;
  z-index: 99999999999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}

.top_cancel_div_pop {
  width: 30px;
  height: 30px;
  background: #314d7e 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.top_cancel_div_pop:hover {
  background: #5c78a9 0% 0% no-repeat padding-box;
}

.top_line_of_div_section > h3 {
  width: 277px;
  height: 22px;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}
.top_line_of_div > h3 {
  width: 277px;
  height: 22px;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.top_cancel_div_pop > img {
  width: 18px;
  height: 18px;
}

.middle_section_container {
  display: flex !important;
  justify-content: space-between !important;
  width: 89%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 10px;
}

.acc_left {
  display: flex;
}
.beneTxnViewAllchild1 {
  display: flex;
}

.totalCountDiv {
  font: normal normal normal 600 14px / 19px Open Sans;
  color: #f9f9f9;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.totalCount {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 20px;
}
.view-count {
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0px;
  color: #dadada;
  font-style: Open, Sans;
}

.bene-acc_viewall_amount_div {
  /* font-weight: bold !important; */
  font-family: Open Sans, Semibold;
  font-size: 20px;
  height: 28px;
  text-align: right;
  color: #fff;
}

.No-of-accounts {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 12px;
  margin-top: 21px;
}

/* .all-container {
background: linear-gradient(to right, #1d3a6d 60%, #244277 40%);
} */
.bene_acc_filterdropdown-container-div {
  background-color: #3a5686;
  /* margin-top: 170px; */
  height: 80px;
  border-radius: 0 !important;
  position: fixed;
  z-index: 9;
  display: flex;
  justify-content: center;
}

.bene_acc_filterdropdown-container-divm {
  background-color: #314c7f;
  /* margin-top: 177px; */
  margin-top: 157px;

  height: 80px;
  border-radius: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.bene_acc_total_count_symbol {
  transform: matrix(0, -1, 1, 0, 0, 0);
  font-style: italic;
  color: #ffffff;
  margin-right: 4px;
  font-size: 1.125rem;
}

.filter-container_acc {
  display: flex;
  justify-content: space-between;

  width: 55px;
  height: 30px;
  padding: 9px;
  background: #244277;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 5px;

  position: relative;
}

.beneacc_filter_div.filtered .filter-container_acc {
  background: #f9f9f9;
  /* margin-left: auto; */
}

.filter-container_acc:hover {
  background-color: #506994;
}

.filter-image {
  width: 14px !important;
  height: 14px;
}

.bene-gradient {
  height: 125px;
  position: absolute;
  /* width: 40%;*/
  background-color: #506994;
  top: 15px;
}

.bene-acc-no-acc-image {
  height: 230px;
  opacity: 0.9;
}

.bene-acc-no-beneaccount-text,
.no-beneaccount-text2,
.no-beneaccount-text {
  color: #ffffff;
  opacity: 1;
  font-size: 1rem;
  text-align: center;
}

.no-beneaccount-text2 {
  display: inline-flex;
  padding-bottom: 18px;
}

.loader-container > img {
  width: 49px !important;
  height: 50px !important;
}

.filter--div--left {
  display: flex;
  align-items: center;
  /* margin-top: 38px;
margin-right: 305px; */
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.filter--div--left p {
  top: 149px;
  left: 330px;
}

.filter--div--left .filter-body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* width: 185px; */
  height: 43px;
  /* box-shadow: 0px 3px 6px #00000024; */
  border: 2px solid #667ca3;
  border-radius: 48px;
  opacity: 1;
  margin-left: 10px;
  font-size: 12px;
  color: #ffffff;
}
.filter--div--left .filter-bodydiv {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  /* width: 185px; */
  height: 43px;
  /* box-shadow: 0px 3px 6px #00000024; */
  border: 2px solid #667ca3;
  border-radius: 48px;
  opacity: 1;
  margin-left: 10px;
  font-size: 12px;
  color: #ffffff;
}

.filter--div--left .img-div {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 26px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filter--div--left .img-div > img {
  width: 14px;
  height: 14px;
}

.filter--div--left .close-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  background-color: #667ca3;
  border-radius: 26px;
  opacity: 1;
}

.filter--div--left .close-btn > img {
  height: 40px;
  width: 31px;
}

.infinityScrollContainerAccBeneView {
  display: flex !important;
  flex-direction: column !important;
  overflow: auto;
  position: relative;
  height: 100%;
  margin-bottom: 0px !important;
}
#infinityScrollContainerBeneView {
  overflow-y: auto;
  height: 100%;
}
.beneCard {
  left: 5rem;
  padding-top: 0px;
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .beneCard {
    left: 6rem;
  }
}

@media screen and (min-width: 2560px) and (min-height: 1440px) {
  .beneCard {
    left: 8rem;
  }

  .filter--div--left {
    margin-right: 295px;
  }
}

@media screen and (max-width: 3840px) and (min-width: 2560px) {
  .filter--div--left {
    margin-right: 295px;
  }
}

@media screen and (min-width: 3840px) and (min-height: 1920px) {
  .beneCard {
    left: 12rem;
  }
}

.shadow-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.bene_acc_filterdropdown {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1024px !important;
  border-top: 1px solid #031f4f;
}

.beneacc_approval_heder_containermv {
  background-color: #314c7f;
  background-color: #314c7f;
  margin-top: 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

.accBeneapprovalheadertextvcv {
  width: 100%;
  padding: 0 20px;
  display: table;
  color: #ffffff;
  margin-top: 66px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #506994;
  z-index: 4;
}

.txnBeneapprovalheadertextv {
  width: 100%;
  /* padding: 20px 10px; */
  border-bottom: 2px solid #1d3a6d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.txnBeneapprovalheaderarrowvcv {
  display: table-cell;
  width: 10%;
  vertical-align: middle;
  cursor: pointer;
}

.txnBeneapprovalheadertextmv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 0px 10px;
}

.txnBeneapprovalheaderfilterv {
  display: table-cell;
  width: 100px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.beneaccViewAllParentdivm {
  display: flex;
  flex-direction: column;
  gap: 04px;
  width: 100%;
  float: left;
  /* max-width: 200px; */
}

.txnimgfmv {
  display: table;
  width: 100%;
  margin-left: 25px;
  vertical-align: middle;
  cursor: pointer;
}

.txnimgsv {
  display: table-cell;
  width: 33%;
  vertical-align: middle;
  cursor: pointer;
  padding: 5px;
  border-right: 2px solid #1e3a6d;
}

.txnimgfv {
  display: table-cell;
  width: 33%;
  vertical-align: middle;
  cursor: pointer;
  padding: 5px;
  margin-left: 10px;
  /* border-right: 2px solid #1e3a6d; */
}

.txnimgmv {
  display: table-cell;
  width: 33%;
  vertical-align: middle;
  cursor: pointer;
  padding: 5px;
}

.headingAccountDetail {
  font: normal normal 600 26px/39px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 20px;
}

.headertextbottomv {
  font: normal normal 600 12px/23px Open Sans !important;
  letter-spacing: 0px;
  color: #c9c9c9;
  font-size: 12px;
}

.mobilepagenation {
  position: fixed;
  z-index: 2;
  bottom: 0px;
  width: 100%;
  background-color: #3a5686;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 20px;
  z-index: 1px;
}
.bene_acc_viewall_data_dropdown {
  border-radius: 0px 5px 5px 0px;
  padding: 5px 0px 0px 0px;
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.bene_acc_dropdown_options {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffff;
  width: 45px;
  height: 24px;
  font-size: 12px;
  padding-left: 12px;
}
.bene_acc_dropdown_options:hover {
  background-color: rgb(86, 110, 151);
}
.bene-acc_dropdown_open_div {
  position: absolute;
  /* background-color: rgb(102, 124, 163); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  border-radius: 5px;
  /* margin-top: 23px; */
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 55px;
  height: 50px;
  /* top: -114px; */
  bottom: -25px;
  margin-left: 5px;
}

/* @media screen and (min-width: 350px) and (max-width: 750px) {
  #infinityScrollContainerBeneView_beneemptyDataClass {
    height: 45vh !important;
  }
} */
@media screen and (min-width: 700px) and (max-width: 804px) {
  .bene-acc-no-acc-image {
    height: 215px !important;
  }
}

@media screen and (max-width: 800px) {
  .beneTxnViewallCard {
    z-index: 0;
  }

  .acc_black_divm {
    /* min-height: 100vh; */
    height: calc(100vh + 20%);
  }
  #infinityScrollContainerBeneView {
    height: 80%;
  }
}
