.gst-main-div {
    display: flex;
    flex-direction: column;
}

.gst-search {
    /* position: fixed; */
    width: 100%;
    height: 12.125rem;
    background: #3a5686 0% 0% no-repeat padding-box;
    /* box-shadow: 0.3125rem 0.3125rem 0.6875rem #00000026; */
    opacity: 1;
    padding-bottom: 1.6875rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 99;
}

.gst-search-1024 {
    max-width: 64rem;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gst-input-div {
    display: flex;
    position: relative;
    margin-right: 1.25rem;
}

.gst-input-div>input {
    width: 54.75rem;
    height: 2.25rem;
    background: #244277 0% 0% no-repeat padding-box;
    border-radius: 0.625rem;
    opacity: 1;
    padding-left: 1.25rem;
    text-align: left;
    font: normal normal normal 0.75rem/1.1875rem Open Sans;
    letter-spacing: 0px;
    color: #DADADA;
    outline: none;
}

.gst-input-div>input::placeholder {
    text-align: left;
    font: normal normal normal 0.875rem/1.1875rem Open Sans;
    letter-spacing: 0px;
    color: #fafafa;
    opacity: 1;
}

.gst-input-div>img {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 1.25rem;
    top: 10px;
    cursor: pointer;
}

.gst-fetch-button {
    width: 8rem !important;
    height: 2.25rem !important;
    background: #f9f9f9 0% 0% no-repeat padding-box ;
    border-radius: 0.625rem !important;
    text-align: center !important;
    font: normal normal 600 0.875rem/1.1875rem Open Sans !important;
    letter-spacing: 0px !important;
    
    text-transform: none !important;
}


.gst-loading-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 22.625rem; */
}

.gst-loading-loader>img {
    width: 2.8125rem;
    height: 2.3125rem;
    opacity: 1;
}

.gst-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gst-not-found>img {
    width: 404px;
    height: 283.2px;
    opacity: 0.9;
    margin-bottom: 1.25rem;
}

/* .gst-not-found>p {
    height: 3.375rem;
    text-align: center;
    font: normal normal normal 1.25rem/1.6875rem Open Sans;
    letter-spacing: 0px;
    color: #fafafa;
    opacity: 1;
} */

.gst-default-img {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gst-default-img>img {
    width: 25rem;
    height: 17.75rem;
    opacity: 1;
    margin-bottom: 1.25rem;
}

.gst-default-img>p {
    text-align: center;
    font: normal normal normal 1rem/1.362rem Open Sans;
    letter-spacing: 0px;
    color: #F9F9F9;
    opacity: 1;
    width: 404px;
    height: 44px;
}