.totp_Container_txn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.totp_stepper_Div {
  width: 100%;
  background-color: #314c7f;
  height: 164px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 2px 2px 10px 0px rgba(26, 26, 26, 0.733);
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
}

.enabled_disabled_top {
  padding: 10px 0px;
}

.enabled_disabled_top > h4 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dadada;
}

.enabled_disabled_top > p {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}

.totp_enabled_disabled_div {
  width: 1024px;
  height: 170px;
  padding: 20px;
  border-radius: 20px;
  background-color: #314d7e;
}

.totp_disabled_div {
  width: 1024px;
  height: 170px;
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #314d7e;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.totp_Enabling_img > img {
  width: 287.8px;
  height: 250px;
}

.totp_Enabling_img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.totp_enabled_disabled_div_1 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dadada;
  margin-bottom: 10px;
}

.totp_disabled_div > h4 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dadada;
}

.totp_disabled_div > p {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}

.totp_enabled_disabled_div_2 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}

.totp_enabled_disabled_div_3 {
  width: 84px;
  height: 32px;
  padding: 8px 16px 8px 16px;
  border-radius: 5px;
  background-color: #69b76f;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  margin: 18px 0px;
}

.totp_enabled_disabled_div_4 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}

.carousel-container-totp {
  max-width: 1024px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 186px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.totp_disabled_div_butt {
  width: 109px;
  height: 32px;
  padding: 8px 16px 8px 16px;
  border-radius: 5px;
  background-color: #f05a6a;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}
