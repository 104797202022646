.content-page {
  width: 100%;
  background-color: #3a5686;
  height: 305px;

  margin-top: 72px;
  padding: 6px;
  box-shadow: 5px 5px 11px #00000026;
}

.content-page-Responsive {
  width: 100%;
  background-color: #3a5686;
  height: 430px;

  /* padding: 6px; */
  box-shadow: 5px 5px 11px #00000026;
}

.container {
  width: 100%;
  background-color: #3a5686;
  height: 71vh;
  height: 30%;

  margin: auto;
}

.container-Responsive {
  width: 100%;
  background-color: #3a5686;

  /* height: 30%; */

  margin: auto;
}

.acc-section {
  height: 30%;
  background-color: #3a5686;
  max-width: 1024px;
  margin: auto;
  display: flex;
}

.acc-section-responsive {
  height: 30%;
  background-color: #314d7e;
  max-width: 1024px;
  margin: auto;
  display: flex;
}

.major-div {
  width: 800px;

  margin-top: 29px;
}

.major-div-responsive {
  width: 100%;
  margin-top: 20px;
}

.individual-bank-detail {
  height: 90px;
  display: flex;
  border-bottom: 2px solid #1d3a6d;
}

.individual-bank-detail-responsive {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #1d3a6d;
  margin-top: 50px;
  /* padding-bottom: 12px; */
}

.acc-balance {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-bottom: 25px;
  width: 38%;
  position: relative;
}

.view-Balance {
  border-top: 2px solid #1d3a6d;

  margin-bottom: 28px;
  width: 100% !important;
  padding-top: 15px;
}

.acc-balance-Responsive {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  /* width: 38%; */
  width: 100%;
  height: auto;
  position: relative;
}

.acc-balance-increased-Responsive {
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
  display: flex;
  /* width: 38%; */
  width: 100%;
  /* height: auto; */
}

.arrow-div {
  position: absolute;
}

.arrow-div-up {
  position: absolute;
  left: 100px;
  top: 40px;
}

.arrow-div-down {
  position: absolute;
  left: 95px;
  bottom: 70px;
}

.acc-number-balance {
  width: 235px;
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;
  margin-left: 20px;
  margin-top: -22px;
}

.refresh-div {
  display: flex;

  justify-content: space-between;
  align-items: center;
  height: 15px;
  margin-left: 6px;
}

.refresh-div-Responsive {
  display: flex;

  justify-content: space-between;
  align-items: center;
  height: 15px;
  margin-left: 6px;
}

.refresh-img-icon {
  color: #f9f9f9 !important;
  height: 33px;
  width: 33px;
  margin-top: 2px;
}

.refresh-text-content {
  font: normal normal normal 11px/26px Open Sans;
  color: #fafafa;
}

.individual-account {
  padding-top: 21px;
  padding-bottom: 21px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;
}

.individual-account-Responsive {
  display: flex;
  flex-direction: column;
  width: auto;
  padding-left: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #1d3a6d;
}

.accno-response {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  margin-bottom: 3px;
}

.popup-header {
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  margin-bottom: 3px;
}

.acc-number-label {
  color: #dadada;
  opacity: 1;
  font-size: 12px;
  font-weight: 400;

  font: Open Sans;
  letter-spacing: 0px;
}

.acc-balance-content {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  font-weight: 600;
  border-left: 2px solid #1d3a6d;
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.acc-balance-content-Reponsive {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  font-weight: 400;

  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.bank-details {
  height: 90px;
  display: flex;
}

.bank-details-Responsive {
  height: 140px;
  display: flex;
  flex-direction: column;
}

.bank-logo-details {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 44%;
}

.bank-logo-details-responsive {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-bank-container {
  background: #fafafa;
  height: 88.5px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-bank-container-responsive {
  background: #fafafa;
  height: 31px;
  width: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.detailed-bank-logo {
  width: 80px;
}
.detailed-bank-logo-responsive {
  width: 58px;
  height: 15px;
}

.individual-account-holdername {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  margin-bottom: 5px;
}

.owner-loader-container {
  img {
    margin-top: 350px !important;
    width: 49px;
    height: 50px;
  }
}

.minor-div {
  width: 373px;

  margin-top: 29px;
}

.acc-content-detail-page {
  justify-content: center;
  height: 48px;
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.acc-status-main {
  border-bottom: 2px solid #1d3a6d;
  height: 90px;
  display: flex;
}

.acc-status-main-responsive {
  /* border-bottom: 2px solid #1d3a6d; */
  /* height: 70px; */
  display: flex;
}

.acc-connected {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;
  font-weight: 600;
}

.acc-connected-responsive {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 20px;
  font-weight: 600;
}

.acc-status-detail-page {
  padding: 25px 0px 20px !important;

  width: 50%;
}

.acc-status-detail-page-responsive {
  /* padding-bottom: 10px; */
}

.acc-status-content {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  border-left: 2px solid #1d3a6d;
  height: 47px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.acc-balance-content-responsive {
  color: #fafafa;
  opacity: 1;
  /* font-size: 1.2rem; */
  font-weight: 400;
  height: 47px;
  align-items: flex-start;
  padding-left: 20px;
}
.acc-balance-increased-content-responsive {
  color: #fafafa;
  opacity: 1;
  /* font-size: 1.2rem; */
  font-weight: 400;
  height: 47px;
  align-items: flex-start;
  padding-left: 20px;
}

.active-status {
  color: #4ddd37;
}

.not-active-status {
  color: #ff5860;
}

.recipient {
  height: 90px;
  border-bottom: 2px solid #1d3a6d;
  display: flex;
}

.recipient-box2 {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 34%;
  border-right: 2px solid #1d3a6d;
}

.akh[disabled] {
  background-color: #506994 !important;
}

.recipient-box1 {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 33%;
  margin-bottom: 5px;
  border-right: 2px solid #1d3a6d;
}

.recipient-box3 {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 34%;
}

.recipient-box5 {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 44%;
}

.recipient-box5-responsive {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
}

.recipient-box5-responsive {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
}

.recipient-box6 {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 44%;
  cursor: pointer;
}

.recipient-box6-responsive {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  border-left: 2px solid #1d3a6d;
  width: 34.3%;
}

.recipient-box4 {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 33%;
}

.recipient-img {
  width: 30px;
  height: 30px;
}

.image-label {
  font-size: 10px;
  font-weight: 400;
  color: #f9f9f9 !important;
}

.title2 {
  color: #dadada;
  margin-bottom: 10px;
}

.statement {
  height: 90px;
  border-bottom: 2px solid #1d3a6d;
}

.empty-statement {
  width: 66%;
  background-color: #3a5686;
}

.statement-view-acc {
  height: 90px;
}

.statement-img {
  background: #506994;
  opacity: 1;
}

.signature-error-div {
  width: 68%;
  background-color: #3a5686 !important;
}

.details-div-section {
  width: 100%;
  background-color: #1d3a6d;
  margin-top: 240px !important;
  margin-bottom: 20px;
}

.details-div-section-responsive {
  width: 100%;
  background-color: #314d7e;
  margin-top: 20px !important;
  margin-bottom: 20px;
}

.details-section {
  height: auto;
  background-color: #1d3a6d;
  max-width: 1024px;
  margin: auto;
}

.details-section-responsive {
  height: 39px;
  background-color: #1d3a6d;
  max-width: 1024px;
  margin: auto;
}

.details-btn {
  width: 80px;
  height: 30px;
  background-color: #f9f9f9;
  border-radius: 18px;
  margin-right: 20px;
  color: #3a3649;
  font-size: 0.75rem;
  text-align: center;
  font-style: Open, Sans;
  font-weight: 600;
  text-align: center;
}

.bank-detail-card {
  width: 328px;
  height: 122px;
  background-color: #314d7e;
  border-radius: 20px;
  padding: 20px;
}

.bank-detail-card-responsive {
  width: auto;
  height: 118px;
  margin-bottom: 20px;
  background-color: #314d7e;
  border-radius: 10px;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.assigned-detail-card {
  width: 1024px;
  position: relative;
  background-color: #314d7e;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px !important;
}

.assigned-detail-card-responsive {
  width: 1024px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  background-color: #314d7e;
  border-radius: 10px;
  box-shadow: -5px -5px 11px #71717133;
  padding: 10px;
  margin-bottom: 30px !important;
}

.bank-card-row {
  display: flex;
  flex-direction: row;
  margin: 20px 0px 30px 0px;
  gap: 20px;
}

.bank-card-row-responsive {
  flex-direction: row;
  margin: 20px 0px 0px 0px;
  gap: 20px;
  padding-bottom: 100px !important;
}

.assigned-card-row {
  display: flex;
  flex-direction: row;
  margin: 20px 0px 30px 0px;
  gap: 20px;
}

.assigned-card-row-responsive {
  display: flex;
  padding-bottom: 100px;
  flex-direction: row;
  margin: 20px 0px 30px 0px;
  gap: 20px;
}

.ifsc-response {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  width: 125px;
}

.pan-response {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  width: 125px;
}

.data-div {
  font-size: 0.9rem;
  color: #f9f9f9;
  padding-bottom: 4px;
}

.basic-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: #c9c9c9;
  padding-bottom: 10px;
  border-bottom: 2px solid #1d3a6d;
  width: 100%;
}

.accno-details {
  padding-top: 27px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-left: 1px;
}

.accno-details-img {
  background-color: #f9f9f9;
  width: 82px;
  height: 26px;
  border-radius: 20px;
  margin-right: 20px;
  margin-top: 5px;
}

.accno-details-detailed-bank-logo {
  padding: 5px 10px;
}

.accno-details-Reponsive {
  /* padding-top: 10px;
  padding-bottom: 10px; */
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
  width: auto;
  padding-left: 20px;
  border-bottom: 2px solid #1d3a6d;
}

.ifsc-div-main {
  padding-bottom: 20px;
  padding-top: 20px;
  width: 185px;

  /* height: 80px; */
}

.ifsc-div-main-Resposive {
  /* padding-bottom: 10px;
  padding-top: 10px; */
  width: auto;
  border-bottom: 2px solid #1d3a6d;
  display: flex;
  justify-content: space-between;
}

.ifsc-div-content {
  padding-top: 5px;
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  border-left: 2px solid #1d3a6d;
  height: 50px;
  width: 200px;
  padding-right: 10px;
  display: flex;

  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.pan-div-content {
  padding-top: 5px;
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  height: 55px;
  width: 298px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.pan-div-content-responsive {
  padding-top: 5px;
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  height: 55px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.ifsc-div-content-Responsive {
  /* padding-top: 5px; */
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  padding-top: 15px;
  padding-bottom: 15px;

  /* height: 50px; */
  /* width: 200px; */
  padding-right: 10px;
  display: flex;

  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.ifsc-div-main:hover {
  width: 144px;
  height: 59px;
}

.ifsc-code-element {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;

  margin-top: 2px;
  font-size: 12px;

  /* padding-bottom: 10px; */
  width: 100%;
}

.pan-number-element {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;

  margin-top: 2px;
  font-size: 12px;

  /* padding-bottom: 10px; */
  width: 100%;
}

.acc-type-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: #dadada;

  width: 100%;
}

.owner-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: #dadada;

  width: 100%;
}

.statementNotFoundError {
  height: 90px;
  background: #bb5353 0% 0% no-repeat padding-box;
  opacity: 1;
}

.owner-detail-card {
  width: 328px;
  height: 253px;
  background-color: #314d7e;
  border-radius: 20px;
  padding: 20px;
}

.support-detail-cards {
  width: 328px;
  height: 122px;
  background-color: #314d7e;
  border-radius: 20px;
  padding: 20px;
}

.support-detail-card-responsive {
  /* width: 328px; */
  width: auto;
  /* height: 250px; */
  background-color: #314d7e;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.buttonsforOwnerdetails {
  display: flex;
  align-items: center;
}

.buttonsforOwnerdetails-responsive {
  display: flex;
  height: 39px;
  align-items: center;
  background-color: #314d7e;
  width: 100% !important;
}

.DetailsOwnerButton {
  background-color: #373f4b;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DetailsOwnerButton-responsive {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
}

.AssignedButton {
  background-color: #373f4b;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AssignedButton-responsive {
  height: 39px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.owner_div_Acc {
  font-size: 0.8rem;
  color: #dadada;
  padding-bottom: 20px;
  width: 100%;
}

/* .bank-logo-details::after {
  content: "";
  position: absolute;
  background-color: #1d3a6d;
  width: 1px;
  height: 60px;
  transform: translateY(25%);
  right: 0;
} */

.title5 {
  font-weight: 400;
  color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 20px;
}

.DetailsOwnerButton-responsive.effective,
.AssignedButton-responsive.effective {
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 39px;
}

.DetailsOwnerButton.effective,
.AssignedButton.effective {
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.editTag {
  width: 38px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  background-color: #506994;
  top: 0;
  right: 0;
  border-radius: 0px 22px;
  opacity: 1;
}

.disableedittag {
  cursor: pointer !important;
  opacity: 0.6;
}

.edit-icon {
  width: 15px;
  height: 15px;
  position: relative;
  top: 8px;
  left: 12px;
}

.NotagsAssigned {
  color: #dadada;
  font: normal normal 400 14px/22px Open Sans;
}

.ownerdetail-border-div {
  height: 60px;
  margin: 13px 0 0 0;
  border-right: 1px solid #1d3a6d;
}

.ownerdetail-border-div-bottom {
  width: 288px;
  border-bottom: 1px solid #1d3a6d;
}

@media only screen and (max-width: 531px) {
  .ownerdetail-border-div-bottom-responsive {
    width: 93%;
    border-bottom: 1px solid #1d3a6d;
  }
}

@media only screen and (min-width: 531px) {
  .ownerdetail-border-div-bottom-responsive {
    width: 92%;
    border-bottom: 1px solid #1d3a6d;
  }
}
